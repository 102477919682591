"use client";

import { I18nMessageClient } from "@packages/i18n";
import type { SidebarProps } from "@packages/ui";
import { Sidebar, Text } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useParams } from "next/navigation";

import { GroupsList } from "../GroupsList";

const styles = stylex.create({
  base: {
    gap: spacing.xxs,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  header: {
    marginBottom: spacing.s,
    paddingBottom: spacing.s,
    paddingHorizontal: spacing.m,
  },
});

export type ParishAdminDashboardGroupsSidebarProps = Omit<
  SidebarProps,
  "children"
>;

export const ParishAdminDashboardGroupsSidebar = ({
  state,
  ...props
}: ParishAdminDashboardGroupsSidebarProps) => {
  const { communityId } = useParams<{ communityId: string }>();

  return (
    <Sidebar state={state} styleXArray={[styles.base]} {...props}>
      <Text type="headline" size="m" styleXArray={[styles.header]}>
        <I18nMessageClient message="community_small_groups_title" />
      </Text>
      <GroupsList communityId={parseInt(communityId)} />
    </Sidebar>
  );
};
