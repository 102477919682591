"use client";

import { GoogleTagManager as GTM } from "@next/third-parties/google";
import { Consent, useConsent } from "@packages/consent";

export const GoogleTagManager = () => {
  const { consent } = useConsent();

  if (
    Array.isArray(consent) &&
    consent.includes(Consent.ANALYTICS) &&
    consent.includes(Consent.ADVERTISING)
  ) {
    return <GTM gtmId={process.env.NEXT_PUBLIC_GTM_ID} />;
  }

  return null;
};
