"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useLocale } from "@packages/i18n";
import { Skeleton } from "@packages/ui";
import {
  semanticColors,
  spacing,
  zIndices,
} from "@packages/ui/global/stylex/vars.stylex";
import {
  BraintreePayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import * as stylex from "@stylexjs/stylex";
import client from "braintree-web/client";
import paypalCheckout from "braintree-web/paypal-checkout";
import { useEffect, useState } from "react";

import type { CheckoutProps } from "./Checkout";

const styles = stylex.create({
  button: {
    alignItems: "center",
    background: semanticColors.neutralsLowest,
    border: 0,
    borderRadius: 10,
    color: semanticColors.primary,
    cursor: "pointer",
    display: "flex",
    fontSize: "0.875rem",
    fontWeight: "600",
    height: "100%",
    left: 0,
    letterSpacing: 0.25,
    paddingLeft: 20,
    paddingRight: 20,
    pointerEvents: "none",
    position: "absolute",
    textAlign: "left",
    top: 0,
    width: "100%",
    zIndex: zIndices.absolute,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    marginRight: spacing.ms,
    width: "32px",
  },
  invisible: {
    opacity: 0,
  },
  skeleton: {
    borderRadius: 10,
    height: 55,
  },
  wrapper: {
    height: 55,
    position: "relative",
    width: "100%",
  },
});

const PaypalIcon = () => (
  <svg
    viewBox="0 0 16 16"
    width={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    focusable="false"
  >
    <path
      d="M12.901 3.838c.194.064.374.14.54.227.195-1.244-.002-2.092-.675-2.859C12.024.362 10.686 0 8.973 0H3.999a.71.71 0 0 0-.702.6L1.225 13.732c-.04.26.16.494.422.494h3.07l.77-4.883-.023.145c.055-.345.35-.6.7-.6h1.46c2.865 0 5.11-1.164 5.765-4.53.02-.1.037-.197.052-.292a3.55 3.55 0 0 0-.54-.227Z"
      fill="#253B80"
    />
    <path
      d="M12.754 3.792a5.164 5.164 0 0 0-.638-.142 8.103 8.103 0 0 0-1.286-.094H6.932a.619.619 0 0 0-.615.526l-.829 5.252-.024.153c.055-.345.35-.6.7-.6h1.46c2.866 0 5.11-1.164 5.765-4.53.02-.1.036-.197.051-.292a3.5 3.5 0 0 0-.686-.274Z"
      fill="#222D65"
    />
    <path
      d="M13.44 4.065a8.805 8.805 0 0 1-.05.292c-.657 3.367-2.9 4.53-5.766 4.53h-1.46a.709.709 0 0 0-.7.6l-.747 4.738-.211 1.343a.373.373 0 0 0 .368.432h2.588a.623.623 0 0 0 .615-.525l.026-.131.487-3.093.031-.17a.622.622 0 0 1 .615-.525h.387c2.508 0 4.47-1.018 5.044-3.964.24-1.23.116-2.258-.518-2.98a2.475 2.475 0 0 0-.709-.547Z"
      fill="#179BD7"
    />
  </svg>
);

type PaypalButtonProps = {
  type: "paypal" | "paylater";
  onClick: () => void;
  onInit: () => void;
  onError?: (error: any) => void;
} & CheckoutProps;

const PaypalButton = ({
  total,
  currency,
  type,
  mode,
  onClick,
  onSubmit,
  onInit,
  onError,
}: PaypalButtonProps) => {
  const locale = useLocale();
  const order =
    mode === "subscription"
      ? {
          createBillingAgreement: (_, actions) => {
            return actions.braintree.createPayment({
              flow: "vault",
              amount: total,
              currency,
              enableShippingAddress: true,
              locale: locale,
            });
          },
        }
      : {
          createOrder: (_, actions) => {
            return actions.braintree.createPayment({
              flow: "checkout",
              intent: "capture",
              amount: total,
              currency,
              enableShippingAddress: true,
            });
          },
        };
  return (
    <BraintreePayPalButtons
      style={{
        borderRadius: 10,
        color: "silver",
        disableMaxWidth: true,
        height: 55,
        layout: "vertical",
      }}
      disabled={false}
      fundingSource={type} // Available values are: ["paypal", "card", "credit", "paylater", "venmo"]
      forceReRender={[currency, total, type]}
      {...order}
      onClick={onClick}
      onInit={() => onInit()}
      onError={onError}
      onApprove={function (data, actions) {
        return actions.braintree.tokenizePayment(data).then((payload) => {
          const { countryCode, postalCode, recipientName, ...address } =
            payload.details.shippingAddress;
          onSubmit({
            paymentPlatform: "paypal",
            paymentMethod: payload.nonce,
            name: `${payload.details.firstName} ${payload.details.lastName}`,
            email: payload.details.email,
            address: {
              ...address,
              postal_code: postalCode,
              country: countryCode,
            },
          });
        });
      }}
    />
  );
};

export const PaypalElement = (props: CheckoutProps) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>(null);

  const { track } = useAnalytics();

  const dataUserIdToken = process.env.NEXT_PUBLIC_BRAINTREE_TOKEN;

  const handleOnClick = () =>
    track({ event: HallowAnalyticsEvent.TappedPaymentPaypal });

  useEffect(() => {
    const setupBraintree = async () => {
      const clientInstance = await client.create({
        authorization: dataUserIdToken,
      });
      const paypalInstance = await paypalCheckout.create({
        client: clientInstance,
      });
      const clientId = await paypalInstance.getClientId();
      setClientId(clientId);
    };
    setupBraintree();
  }, []);

  return (
    <>
      {clientId && (
        <PayPalScriptProvider
          options={{
            clientId,
            dataUserIdToken,
            components: "buttons",
            intent: "capture",
            vault: true,
          }}
        >
          <div {...stylex.props(styles.wrapper)}>
            <Skeleton styleXArray={[styles.skeleton]}>
              <div {...stylex.props(styles.invisible)}>
                <PaypalButton
                  {...props}
                  onClick={handleOnClick}
                  onInit={() => setIsShowing(true)}
                  type="paypal"
                />
              </div>
            </Skeleton>
            {isShowing && (
              <button {...stylex.props(styles.button)}>
                <div {...stylex.props(styles.iconContainer)}>
                  <PaypalIcon />
                </div>
                <span>PayPal</span>
              </button>
            )}
          </div>
        </PayPalScriptProvider>
      )}
    </>
  );
};
