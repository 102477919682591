"use client";

import { useTranslations } from "@packages/i18n";
import type { PaymentResult } from "@packages/sdk";
import { Button, TextInput } from "@packages/ui";
import {
  colors,
  fontSizes,
  lineHeights,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
  stroke,
} from "@packages/ui/global/stylex/vars.stylex";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import * as stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";

const styles = stylex.create({
  form: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: spacing.m,
    width: spacing.full,
  },
  cardButton: {
    borderRadius: radius.ms,
  },
  cardInput: {
    backgroundColor: colors.transparent,
    borderColor: semanticColors.neutralsLowest,
    borderRadius: radius.ms,
    borderStyle: "solid",
    borderWidth: stroke.regular,
    color: semanticColors.primary,
    fontSize: fontSizes.bodyL,
    lineHeight: lineHeights.bodyM,
    minHeight: numericPixels[64],
    outline: "none",
    padding: `${spacing.l} ${spacing.m}`,
    transition: "border 200ms ease-in",
    width: numericPercentages[100],
    ":hover": {
      borderColor: semanticColors.neutralsVeryLow,
    },
    ":focus": {
      borderColor: semanticColors.primary,
      outline: "none",
    },
  },
});

type StripeCardProps = {
  buttonLabel: ReactNode;
  showEmail: boolean;
  onSubmit: (details: PaymentResult) => void;
};

export const StripeCardElement = ({
  buttonLabel,
  showEmail,
  onSubmit,
}: StripeCardProps) => {
  const t = useTranslations();
  const stripe = useStripe();
  const elements = useElements();
  const onCardSubmit = async ({ name, email }) => {
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name,
        email,
      },
    });
    onSubmit({
      paymentMethod: paymentMethod.id,
      paymentPlatform: "stripe",
      name,
      email,
    });
  };
  const [hasCard, setHasCard] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<Partial<PaymentResult>>();
  return (
    <form {...stylex.props(styles.form)} onSubmit={handleSubmit(onCardSubmit)}>
      <TextInput.Field
        control={control}
        name="name"
        placeholder={t("user_payment_cardholder_placeholder")}
        required
      />
      {showEmail && (
        <TextInput.Field
          control={control}
          type="email"
          name="email"
          placeholder={t("general_email")}
        />
      )}
      <CardElement
        {...stylex.props(styles.cardInput)}
        options={{ style: { base: { fontSize: "16px" } } }}
        onChange={(event) => setHasCard(event.complete)}
      />
      <Button
        type="submit"
        disabled={!isDirty || !isValid || isSubmitting || !hasCard}
        size="l"
        isFullWidth={true}
      >
        {buttonLabel}
      </Button>
    </form>
  );
};
