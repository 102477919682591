"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { User } from "@packages/sdk";
import { useRequestCommunitiesAdminMember } from "@packages/sdk";
import type { StyleXArray } from "@packages/ui";
import {
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  MinusIcon,
  MoreIcon,
  SwapIcon,
  useAlert,
} from "@packages/ui";
import {
  dropShadow,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useRouter } from "next/navigation";

const styles = stylex.create({
  dropdownMenuItem: {
    columnGap: spacing.s,
    minWidth: "225px",
    padding: `${spacing.xs} ${spacing.s}`,
  },
  dropdownMenu: {
    boxShadow: `${dropShadow.none} ${dropShadow.ms} ${dropShadow.m} ${dropShadow.none} ${semanticColors.fixedDarkenVeryLow}`,
  },
  dropdownMenuItemError: {
    color: {
      default: semanticColors.errorMedium,
      ":hover": semanticColors.errorMedium,
      ":focus": semanticColors.errorMedium,
      ":active": semanticColors.errorMedium,
    },
  },
});

type EditMemberDropdownMenuProps = {
  communityId: number;
  user: Pick<User, "id" | "is_admin"> & { is_self: boolean } & {
    is_only_admin: boolean;
  };
  triggerStyleXArray?: StyleXArray;
};

export const EditMemberDropdownMenu = ({
  communityId,
  user,
  triggerStyleXArray = [],
}: EditMemberDropdownMenuProps) => {
  const t = useTranslations();
  const alert = useAlert({ button: "Ok" });
  const onlyAdminAlert = useAlert({
    button: t("onboarding_button_continue"),
  });

  const router = useRouter();

  const analytics = useAnalytics();

  const {
    mutationDelete: deleteUser,
    mutationPostAdminGrant: grantAdminRights,
    mutationPostAdminRevoke: revokeAdminRights,
  } = useRequestCommunitiesAdminMember({
    communityId: communityId,
    userId: user.id,
    mutationProps: {
      onSuccess: () => {
        if (user.is_self && user.is_admin) {
          // This is to prevent the user from accessing PAD if they no longer have admin rights to any community
          router.refresh();
        }
      },
      onError: (error) => {
        alert.show({
          title: t("general_word_error"),
          description: error.message,
        });
      },
    },
  });

  const handleChangeAdminRights = () => {
    if (user.is_admin) {
      analytics.track({
        event: HallowAnalyticsEvent.TappedAssignAsMember,
      });
      if (user.is_only_admin && user.is_self) {
        onlyAdminAlert.show({
          title: t("pad_choose_a_new_admin"),
          description: t("pad_before_you_assign_yourself_as_member"),
        });
      } else {
        revokeAdminRights.mutate();
      }
    } else {
      analytics.track({
        event: HallowAnalyticsEvent.TappedAssignAsAdmin,
      });
      grantAdminRights.mutate();
    }
  };

  const handleDeleteMember = () => {
    if (user.is_only_admin && user.is_self) {
      onlyAdminAlert.show({
        title: t("pad_choose_a_new_admin"),
        description: t("pad_before_you_leave_the_group"),
      });
    } else {
      analytics.track({
        event: user.is_self
          ? HallowAnalyticsEvent.TappedLeaveGroup
          : HallowAnalyticsEvent.TappedRemoveCommunityMember,
      });
      deleteUser.mutate();
    }
  };

  return (
    <DropdownMenu
      trigger={
        <IconButton
          icon={<MoreIcon />}
          styleXArray={triggerStyleXArray}
          variant="transparentNeutral"
          onPointerDown={() => {
            analytics.track({
              event: HallowAnalyticsEvent.TappedCommunityMemberOptions,
            });
          }}
        />
      }
      contentStyleXArray={[styles.dropdownMenu]}
      contentProps={{ collisionPadding: 20 }}
    >
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          handleChangeAdminRights();
        }}
        styleXArray={[styles.dropdownMenuItem]}
        startIcon={<SwapIcon />}
      >
        {user.is_admin
          ? t("community_member_option_assign_as_member")
          : t("community_member_option_assign_as_admin")}
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteMember();
        }}
        style={styles.dropdownMenuItem}
        startIcon={<MinusIcon.Outline />}
        styleXArray={[styles.dropdownMenuItem, styles.dropdownMenuItemError]}
      >
        {user.is_self
          ? t("community_member_option_leave_group")
          : t("community_member_option_remove")}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};
