"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import type { Community } from "@packages/sdk";
import {
  useActiveSidebarTab,
  useRequestCommunitiesAdminCommunity,
} from "@packages/sdk";
import type { StyleXArray } from "@packages/ui";
import {
  DropdownMenu,
  DropdownMenuItem,
  IconButton,
  MinusIcon,
  MoreIcon,
  useAlert,
} from "@packages/ui";
import {
  dropShadow,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useRouter } from "next/navigation";

import { getBasePADUrl, padTabsPaths } from "../../../../lib";

const styles = stylex.create({
  dropdownMenuItem: {
    columnGap: spacing.s,
    minWidth: "225px",
    padding: `${spacing.xs} ${spacing.s}`,
  },
  dropdownMenu: {
    boxShadow: `${dropShadow.none} ${dropShadow.ms} ${dropShadow.m} ${dropShadow.none} ${semanticColors.fixedDarkenVeryLow}`,
  },
  dropdownMenuItemError: {
    color: {
      default: semanticColors.errorMedium,
      ":hover": semanticColors.errorMedium,
      ":focus": semanticColors.errorMedium,
      ":active": semanticColors.errorMedium,
    },
  },
});

type EditGroupDropdownMenuProps = {
  communityId: number;
  childCommunity: Pick<Community, "id" | "name">;
  triggerStyleXArray?: StyleXArray;
};

export const EditGroupDropdownMenu = ({
  communityId,
  childCommunity,
  triggerStyleXArray,
}: EditGroupDropdownMenuProps) => {
  const t = useTranslations();
  const alert = useAlert({ button: "Ok" });
  const analytics = useAnalytics();
  const router = useRouter();

  const activeSidebarTab = useActiveSidebarTab({
    baseUrl: getBasePADUrl({ communityId }),
    tabs: padTabsPaths,
  });

  const { mutationUnlink: unlinkChildCommunity, query: communityQuery } =
    useRequestCommunitiesAdminCommunity({
      id: communityId,
    });

  const handleUnlinkGroup = () => {
    unlinkChildCommunity.mutate(
      { id: childCommunity.id },
      {
        onError: (error) =>
          alert.show({
            title: t("general_word_error"),
            description: error.message,
          }),
        onSuccess: () => {
          router.push(`/admin/${communityId}/${activeSidebarTab}`);
        },
      },
    );
  };

  return (
    <DropdownMenu
      trigger={
        <IconButton
          icon={<MoreIcon />}
          variant="transparentNeutral"
          onPointerDown={() => {
            analytics.track({
              event: HallowAnalyticsEvent.TappedUnlinkSmallGroup,
              properties: {
                small_group_id: childCommunity.id,
                small_group_name: childCommunity.name,
                parent_id: communityId,
                parent_name: communityQuery.data?.name,
              },
            });
          }}
          styleXArray={triggerStyleXArray}
        />
      }
      contentStyleXArray={[styles.dropdownMenu]}
      contentProps={{ collisionPadding: 20 }}
    >
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          handleUnlinkGroup();
        }}
        styleXArray={[styles.dropdownMenuItem, styles.dropdownMenuItemError]}
        startIcon={<MinusIcon.Outline />}
      >
        {t("community_member_option_remove")}
      </DropdownMenuItem>
    </DropdownMenu>
  );
};
