"use client";

import { useFormatter, useTranslations } from "@packages/i18n";
import type { Cart } from "@packages/sdk";
import {
  discountPercent as totalDiscountPercent,
  dueToday,
  hasSpecifiedEndDate,
  hasTrialDaysGrant,
  isFamilyPlan,
  onlyNewUsers,
  trialDays as totalTrialDays,
} from "@packages/sdk";
import type { ReactNode } from "react";
import { useMemo } from "react";

const PROMO_CODE_GROUP = {
  HDP: "hdp",
  GIFT_CARD: "gift_card",
};

const DAYS_PER_YEAR = 365;
const DAYS_PER_LIFETIME = DAYS_PER_YEAR * 5;
const DAYS_PER_MONTH = 30;

export type CartSummary = {
  banner: ReactNode;
  primaryDetail: ReactNode;
  secondaryDetail: ReactNode;
  tertiaryDetail: ReactNode;
  finePrint: ReactNode;
  button: string;
  attributes: {
    renewalPretax: string;
    renewalPrice: string;
    renewalTax: string;
    subtotalPrice: string;
    tax: string;
    todayPrice: string;
    totalPrice: string;
    trialDays: number;
    trialEndDate: string;
    trialMonths: number;
  };
  $: (amount: number, decimals?: number) => string;
};

export const useCartSummary = (cart: Cart): CartSummary => {
  const t = useTranslations();
  const format = useFormatter();

  const $ = (amount, decimals = 2) =>
    format.number(amount, {
      style: "currency",
      currency: cart.currency,
      maximumFractionDigits: decimals,
    });
  const totalPrice = $(cart.total);
  const subtotalPrice = $(cart.subtotal);
  const tax = $(cart.tax);
  const introPrice = $(cart.intro_offer?.amount, 0);
  const renewalPrice = $(cart.renewal_total);
  const renewalPretax = $(cart.renewal_pretax_total);
  const renewalTax = $(cart.renewal_tax);
  const todayPrice = $(dueToday(cart), 0);

  const discountPercent = totalDiscountPercent(cart);
  const isDiscounted = cart.total !== cart.subtotal;
  const oneTimeDiscount = discountPercent !== 20; // 20% discounts are eternal

  const trialDays = totalTrialDays(cart);
  const trialMonths = Math.round(trialDays / DAYS_PER_MONTH);
  const trialYears = Math.round(trialDays / DAYS_PER_YEAR);
  const trialEndDate = format.dateTime(new Date(cart.trial_end_date));

  const showMonthlyVerbiage =
    trialDays > DAYS_PER_MONTH * 2 && trialDays % DAYS_PER_MONTH === 0;
  const showYearlyVerbiage = trialDays % DAYS_PER_YEAR === 0;

  const isMonthly = cart.product.billing_period.unit === "month";
  const isYearly = cart.product.billing_period.unit === "year";
  const hasTax = !!cart.tax || !!cart.renewal_tax;

  const banner = useMemo(() => {
    if (cart.promo_code?.group === PROMO_CODE_GROUP.GIFT_CARD) {
      return t("redeem_landing_code_group_gift_card");
    }
    return t("redeem_landing_code_group_promotional");
  }, [cart]);

  const primaryDetail = useMemo(() => {
    // If there is a one time purchase intro offer show language for that
    if (cart.intro_offer) {
      if (hasSpecifiedEndDate(cart)) {
        return t("redeem_landing_one_time_payment", {
          0: introPrice,
          1: trialEndDate,
        });
      }
      return t("redeem_landing_one_time_payment_for", {
        0: introPrice,
        1: showMonthlyVerbiage
          ? t("plural_months", { plural_months: trialMonths })
          : t("plural_days", { plural_days: trialDays }),
      });
    }

    // If trial is more than 5 years, it's a lifetime subscription
    if (trialDays > DAYS_PER_LIFETIME) {
      return t("subscription_title_lifetime_subscription");
    }

    // Prioritize showing discount over end date of promo code
    if (cart.discount && !hasTrialDaysGrant(cart)) {
      return t("redeem_landing_offer_discount", { 0: discountPercent });
    }

    // If we have a specified end date instead of a number of days
    // show different verbiage for when the trial will end
    if (hasSpecifiedEndDate(cart)) {
      if (cart.payment_required) {
        return t("redeem_landing_trial_end_date", { 0: trialEndDate });
      }
      return t("redeem_landing_trial_end_date_free", { 0: trialEndDate });
    }

    // If the trial is in an increment of a year
    if (showYearlyVerbiage) {
      return t("plural_subscription_year_trial", {
        plural_subscription_year_trial: trialYears,
      });
    }

    // If the trial is for a full year
    if (showMonthlyVerbiage) {
      return t("plural_subscription_month_trial", {
        plural_subscription_month_trial: trialMonths,
      });
    }

    // Should it use `checkout_summary_days_for`
    return t("plural_subscription_day_trial", {
      plural_subscription_day_trial: trialDays,
    });

    //
  }, [cart]);

  const secondaryDetail = useMemo(() => {
    if (!cart.payment_required) {
      return t("redeem_landing_condition_no_cc");
    }

    // Cover cases with discounts for a period of time
    if (cart.total !== cart.subtotal) {
      // If you have an intro offer AND a discount
      if (cart.intro_offer) {
        const key = oneTimeDiscount
          ? "checkout_summary_then_first_year_discount"
          : "checkout_summary_then_discount";
        return t.rich(key, {
          0: subtotalPrice,
          1: totalPrice,
          2: discountPercent,
          s: (chunks) => <s>{chunks}</s>,
        });
      }

      // Susbcriptions with only a discount
      if (isYearly) {
        const key = oneTimeDiscount
          ? "checkout_summary_then_first_year"
          : "checkout_summary_billed_annually";
        return t(key, { 0: totalPrice });
      }
    }

    // Cover yearly subs with no discounts or intro offers
    if (isYearly) {
      return t("checkout_summary_then_billed_annually", { 0: subtotalPrice });
    }

    // Only thing left is monthly subs
    return t("checkout_summary_then_billed_monthly", { 0: subtotalPrice });
  }, [cart]);

  const tertiaryDetail = useMemo(() => {
    if (onlyNewUsers(cart)) {
      return t("redeem_landing_condition_new_subscriber");
    }

    if (
      cart.payment_required &&
      isDiscounted &&
      oneTimeDiscount &&
      !cart.intro_offer
    ) {
      return t(
        isYearly
          ? "checkout_summary_then_billed_annually"
          : "checkout_summary_then_billed_monthly",
        { 0: subtotalPrice },
      );
    }
    return null;
  }, [cart]);

  const finePrint = useMemo(() => {
    if (isMonthly) {
      if (hasTax) {
        return t("billed_monthly_after_trial_specify_tax", {
          0: subtotalPrice,
          1: tax,
        });
      }
      return t("billed_monthy_after_trial", { 0: subtotalPrice });
    }
    if (isYearly) {
      if (cart.product.billing_period.number === 2) {
        if (hasTax) {
          return t("billed_biennially_after_trial_specify_tax", {
            0: subtotalPrice,
            1: tax,
          });
        }
        return t("billed_biennially_after_trial", {
          0: subtotalPrice,
        });
      }
      if (isFamilyPlan("product", cart.product) && cart.discount > 0) {
        if (hasTax) {
          return t("billed_annually_after_first_year_specify_tax", {
            0: renewalPretax,
            1: renewalTax,
          });
        }
        return t("billed_annually_after_first_year", {
          0: renewalPrice,
        });
      }
      if (hasTax) {
        return t("billed_annually_after_trial_specify_tax", {
          0: subtotalPrice,
          1: tax,
        });
      }
      return t("billed_annually_after_trial", { 0: subtotalPrice });
    }
    return <></>;
  }, [cart]);

  const button = useMemo(() => {
    if (!cart.payment_required) {
      if (cart.promo_code?.group === PROMO_CODE_GROUP.GIFT_CARD) {
        return t("button_redeem_gift_card");
      }
      return t("button_redeem_offer");
    }

    if (cart.intro_offer) {
      return t("redeem_one_time_payment", { 0: introPrice, 1: trialEndDate });
    }

    if (dueToday(cart) > 0) {
      return t("user_sub_buy_button_text");
    }

    if (showYearlyVerbiage) {
      return t("redeem_trial_zero_dollars_year", {
        0: trialYears,
        1: todayPrice,
      });
    }

    if (showMonthlyVerbiage) {
      return t("redeem_trial_zero_dollars_months", {
        0: trialMonths,
        1: todayPrice,
      });
    }

    return t("redeem_trial_zero_dollars", { 0: trialDays, 1: todayPrice });
  }, [cart]);

  return {
    banner,
    primaryDetail,
    secondaryDetail,
    tertiaryDetail,
    finePrint,
    button,
    attributes: {
      trialDays,
      trialMonths,
      trialEndDate,
      totalPrice,
      subtotalPrice,
      todayPrice,
      tax,
      renewalPretax,
      renewalPrice,
      renewalTax,
    },
    $,
  };
};

type CartSummaryProps = {
  cart: Cart;
  children: (summary: CartSummary) => ReactNode;
};

export const CartSummaryProvider = ({ cart, children }: CartSummaryProps) => {
  const summary = useCartSummary(cart);
  return children(summary);
};
