import { useTranslations } from "@packages/i18n";
import { Button, useConfirm } from "@packages/ui";
import { colorsRaw } from "@packages/ui/global/stylex/vars.stylex";

export const useConfirmRemovePostDialog = () => {
  const t = useTranslations();
  return useConfirm({
    cancelButton: t("general_word_cancel"),
    description: () => t("community_delete_confirmation_message"),
    primaryButton: (onClick) => (
      <Button variant="color" color={colorsRaw.red60} onClick={onClick}>
        {t("community_remove_post_action")}
      </Button>
    ),
    title: () => t("community_moderation_confirm_delete"),
  });
};
