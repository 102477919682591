"use client";

import { useTranslations } from "@packages/i18n";
import { IconButton, InfoIcon, useDialog } from "@packages/ui";
import {
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";

const styles = stylex.create({
  primaryButton: {
    color: semanticColors.accentMedium,
  },
  reportedForHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    padding: spacing.xs,
  },
});

export const StripeInfoDialog = () => {
  const t = useTranslations();

  const dialogReportGuidelines = useDialog({
    description: () => t("giving_stripe_description"),
    showCloseButton: true,
    size: "m",
    title: () => t("giving_what_is_stripe"),
  });

  return (
    <IconButton
      onClick={async () => await dialogReportGuidelines.show()}
      icon={<InfoIcon size={20} />}
      size="s"
      variant="transparentNeutral"
      styleXArray={[styles.icon]}
    />
  );
};
