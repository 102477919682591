"use client";

import type { Community as CommunityType } from "@packages/sdk";
import {
  AvatarCommunity,
  type CSSColorStrict,
  stylexDynamics,
  Tag,
  Text,
} from "@packages/ui";
import {
  radius,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import { useTranslations } from "next-intl";

const styles = stylex.create({
  avatarCommunity: {
    borderRadius: radius.xl,
    flexGrow: 0,
    height: "200px",
    padding: spacing.m,
    width: "200px",
  },
  groupInfoDetailsContainer: {
    alignItems: "center",
    color: semanticColors.neutralsHigher,
    display: "flex",
    gap: spacing.s,
  },
  groupInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  groupInfoTextContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  groupInfoDescriptionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  circle: {
    backgroundColor: semanticColors.neutralsLow,
    borderRadius: radius.full,
    height: spacing.xxs,
    width: spacing.xxs,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
    justifyContent: "center",
  },
});

export type CommunityProps = {
  community: CommunityType;
};

export const Community = ({ community }: CommunityProps) => {
  const t = useTranslations();

  return (
    <div {...stylex.props(styles.container)}>
      {community.image_url && (
        <>
          <AvatarCommunity
            color={community.color_hex as CSSColorStrict}
            imgSrc={community.image_url}
            styleXArray={[
              styles.avatarCommunity,
              stylexDynamics.displayDesktopViewport(),
            ]}
          />
          <AvatarCommunity
            size="xl"
            color={community.color_hex as CSSColorStrict}
            imgSrc={community.image_url}
            styleXArray={[stylexDynamics.hideDesktopViewport()]}
          />
        </>
      )}
      <div {...stylex.props(styles.groupInfoTextContainer)}>
        <Text type="title" size="xl" as="h1">
          {community.name}
        </Text>
        {community.tag_text && (
          <Tag variant="neutral">{community.tag_text}</Tag>
        )}
      </div>
      <div {...stylex.props(styles.groupInfoDescriptionDetailsContainer)}>
        {community.description && (
          <Text type="body" size="m" as="p">
            {community.description}
          </Text>
        )}
        <span {...stylex.props(styles.groupInfoDetailsContainer)}>
          {community.simple_address_label && (
            <Text type="detail" size="l">
              {community.simple_address_label}
            </Text>
          )}
          {community.simple_address_label &&
            community.member_summary.member_count && (
              <div {...stylex.props(styles.circle)} />
            )}
          {community.member_summary.member_count && (
            <Text type="detail" size="l">
              {t("plural_members", {
                plural_members: community.member_summary.member_count,
              })}
            </Text>
          )}
        </span>
      </div>
    </div>
  );
};
