"use client";

import { useTranslations } from "@packages/i18n";
import { Text } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
    height: spacing.full,
    justifyContent: "center",
    paddingHorizontal: spacing.l,
    textAlign: "center",
  },
});

export const GroupsEmptyState = () => {
  const t = useTranslations();
  return (
    <div {...stylex.props(styles.container)}>
      <Text type="title" size="l">
        {t("no_small_groups_title")}
      </Text>
      <Text type="body" size="s">
        {t("no_small_groups_description")}
      </Text>
    </div>
  );
};
