"use client";

import { HallowAnalyticsEvent } from "@packages/analytics";
import {
  usePathMatch,
  useRequestCommunitiesAdminCommunity,
} from "@packages/sdk";
import {
  CommunitySelector,
  ReportedCounter,
  Sidebar,
  type SidebarProps,
  SidebarTab,
} from "@packages/ui";
import { useParams } from "next/navigation";

import { getBasePADUrl, PAD_TABS } from "../../../lib";

export type ParishAdminDashboardSidebarProps = Omit<
  SidebarProps,
  "children" | "state"
>;

export const ParishAdminDashboardSidebar = ({
  ...props
}: ParishAdminDashboardSidebarProps) => {
  const params = useParams<{ communityId: string }>();
  const { query: communityQuery } = useRequestCommunitiesAdminCommunity({
    id: parseInt(params.communityId),
  });
  const matcher = usePathMatch();

  const parishAdminBaseUrl = getBasePADUrl({
    communityId: parseInt(params.communityId),
  });

  const activeTab = PAD_TABS.find((tab) =>
    matcher(`${parishAdminBaseUrl}/${tab.path}`),
  );

  const activeTabSidebarState = activeTab?.sidebarState ?? "expanded";

  return (
    <Sidebar state={activeTabSidebarState} {...props}>
      <CommunitySelector triggerState={activeTabSidebarState} />
      {PAD_TABS.map(({ sidebarState, path, Icon, getHidden, ...props }) => (
        <SidebarTab
          key={path}
          href={`${parishAdminBaseUrl}/${path}`}
          state={activeTabSidebarState}
          analyticsEvent={{
            event: HallowAnalyticsEvent.TappedAdminDashboardTab,
            properties: {
              tab: path,
            },
          }}
          actionRight={
            path === "reported" && (
              <ReportedCounter communityId={parseInt(params.communityId)} />
            )
          }
          icon={<Icon fill="currentColor" />}
          hidden={
            getHidden
              ? communityQuery.data
                ? getHidden(communityQuery.data)
                : true
              : false
          }
          {...props}
        />
      ))}
    </Sidebar>
  );
};
