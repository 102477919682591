"use client";

import { I18nMessageClient } from "@packages/i18n";
import { useRequestCommunityMembers } from "@packages/sdk";
import {
  ListProfile,
  Text,
  useIntersectionObserverRefState,
} from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import { useErrorNotification } from "@packages/ui/src/hooks/useErrorNotification";
import stylex from "@stylexjs/stylex";
import { useEffect, useMemo, useRef } from "react";

import { ParishAdminDashboardMembers } from "../ParishAdminDashboardMembers/ParishAdminDashboardMembers";

const styles = stylex.create({
  base: {
    gap: spacing.xxs,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  header: {
    marginBottom: spacing.s,
    paddingBottom: spacing.s,
    paddingHorizontal: spacing.s,
  },
  memberTypeTitle: {
    paddingBottom: spacing.s,
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    paddingTop: spacing.s,
  },
});

const stylesLoading = stylex.create({
  flex: {
    display: "flex",
  },
  hidden: {
    display: "none",
  },
});

type MembersListProps = {
  communityId: number;
  baseMemberLinkUrl: string;
};

export const MembersList = ({
  communityId,
  baseMemberLinkUrl,
}: MembersListProps) => {
  const infiniteScrollBufferRef = useRef<HTMLDivElement>(null);
  const [infiniteScrollBufferRefShowing] = useIntersectionObserverRefState({
    ref: infiniteScrollBufferRef,
  });

  const { infiniteQuery: communityMembersInfiniteQuery } =
    useRequestCommunityMembers({
      id: communityId,
    });

  useErrorNotification({ isError: communityMembersInfiniteQuery.isError });

  const shouldFetchNextPageCommunityMembersInfiniteQuery =
    infiniteScrollBufferRefShowing &&
    communityMembersInfiniteQuery.hasNextPage &&
    !communityMembersInfiniteQuery.isFetchingNextPage;

  useEffect(() => {
    if (shouldFetchNextPageCommunityMembersInfiniteQuery)
      communityMembersInfiniteQuery.fetchNextPage();
  }, [shouldFetchNextPageCommunityMembersInfiniteQuery]);

  const adminsCommunityMembersInfiniteQueryData = useMemo(
    () =>
      communityMembersInfiniteQuery.data?.pages
        ?.map((page) => page?.results?.filter((result) => result.is_admin))
        .reduce((previous, current) => [...previous, ...current]),
    [communityMembersInfiniteQuery.data],
  );

  const membersCommunityMembersInfiniteQueryData = useMemo(
    () =>
      communityMembersInfiniteQuery.data?.pages
        ?.map((page) => page?.results?.filter((result) => !result.is_admin))
        .reduce((previous, current) => [...previous, ...current]),
    [communityMembersInfiniteQuery.data],
  );

  return (
    <>
      <Text size="s" styleXArray={[styles.memberTypeTitle]} type="title">
        <I18nMessageClient message="community_members_list_admins_section_title" />
      </Text>
      <ParishAdminDashboardMembers
        communityId={communityId}
        members={adminsCommunityMembersInfiniteQueryData}
        totalNumberOfAdmins={adminsCommunityMembersInfiniteQueryData?.length}
        baseMemberLinkUrl={baseMemberLinkUrl}
      />
      <Text size="s" styleXArray={[styles.memberTypeTitle]} type="title">
        {adminsCommunityMembersInfiniteQueryData
          ? `${communityMembersInfiniteQuery.data?.pages[0]?.total - adminsCommunityMembersInfiniteQueryData?.length} `
          : ""}
        <I18nMessageClient message="community_settings_members" />
      </Text>
      <ParishAdminDashboardMembers
        communityId={communityId}
        loadingSkeletonsTotal={5}
        members={membersCommunityMembersInfiniteQueryData}
        totalNumberOfAdmins={adminsCommunityMembersInfiniteQueryData?.length}
        baseMemberLinkUrl={baseMemberLinkUrl}
      />
      <ListProfile
        loading
        ref={infiniteScrollBufferRef}
        styleXArray={[
          communityMembersInfiniteQuery.hasNextPage
            ? stylesLoading.flex
            : stylesLoading.hidden,
        ]}
        title=""
      />
    </>
  );
};
