"use client";

import { useTranslations } from "@packages/i18n";
import {
  hasExpired,
  isMobileSubscription,
  useRequestSubscription,
} from "@packages/sdk";
import { useAlert } from "@packages/ui";

import type { CheckoutProps } from "./Checkout";
import { Checkout } from "./Checkout";

export type CheckoutSubscription = CheckoutProps & {
  priceId: string;
  promoCode?: string;
};

export const CheckoutSubscription = ({
  onSubmit,
  priceId,
  promoCode,
  ...props
}: CheckoutSubscription) => {
  const alert = useAlert();
  const t = useTranslations();

  const {
    query: subscription,
    mutationSubscribe: subscribe,
    mutationUpgradeMobile: upgradeMobile,
  } = useRequestSubscription();

  const handleOnSubmit: CheckoutProps["onSubmit"] = async (paymentResult) => {
    try {
      /**
       * If user has an active mobile subscription, upgrade it
       */
      if (
        isMobileSubscription(subscription.data) &&
        !hasExpired(subscription.data)
      ) {
        await upgradeMobile.mutateAsync({
          priceId,
          promoCode,
          ...paymentResult,
        });
      } else {
        await subscribe.mutateAsync({
          priceId,
          promoCode,
          ...paymentResult,
        });
      }

      await subscription.refetch();

      await onSubmit(paymentResult);
    } catch (error) {
      alert.show({
        title: t("general_word_error"),
        description: error.message,
      });
    }
  };

  return <Checkout onSubmit={handleOnSubmit} {...props} />;
};
