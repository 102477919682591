"use client";

import { Text } from "@packages/ui";
import {
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import type { ReactNode } from "react";

const styles = stylex.create({
  lineItemContainer: {
    borderBottom: `1px solid ${semanticColors.neutralsLowest}`,
    borderTop: `1px solid ${semanticColors.neutralsLowest}`,
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
    padding: `${spacing.l} ${spacing.none}`,
  },
  lineItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  lineItemBold: {
    fontWeight: "700",
  },
});

export const CheckoutLineItem = ({
  label,
  value,
  bold = false,
}: {
  label: ReactNode;
  value: ReactNode;
  bold?: boolean;
}) => {
  return (
    <div {...stylex.props(styles.lineItem)}>
      <Text size="l" type="body" styleXArray={[bold && styles.lineItemBold]}>
        {label}
      </Text>
      <Text size="l" type="body" styleXArray={[bold && styles.lineItemBold]}>
        {value}
      </Text>
    </div>
  );
};

export const CheckoutLineItemContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <div {...stylex.props(styles.lineItemContainer)}>{children}</div>;
};
