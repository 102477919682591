"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import {
  type Community,
  CommunityType,
  useActiveSidebarTab,
  useRequestCommunitiesChildren,
} from "@packages/sdk";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import { AvatarCommunity } from "@packages/ui/src/components/_base";
import { Carousel } from "@packages/ui/src/components/blocks/layout";
import { useCarousel } from "@packages/ui/src/hooks";
import type { CSSColorStrict } from "@packages/ui/src/utils";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useEffect } from "react";

import { getBasePADUrl, padTabsPaths } from "../../../../lib";

const LOADING_SKELETONS_TOTAL = 8;

const styles = stylex.create({
  carousel: {
    maxWidth: `calc(100vw - ${spacing.sidebar})`,
    paddingBottom: spacing.m,
    paddingTop: spacing.m,
  },
});

export type CommunitiesCarouselProps = {
  parentCommunity: Pick<Community, "id" | "type">;
  type: CommunityType;
};

export const CommunitiesCarousel = ({
  parentCommunity,
  type,
}: CommunitiesCarouselProps) => {
  const t = useTranslations();
  const analytics = useAnalytics();

  const activeSidebarTab = useActiveSidebarTab({
    baseUrl: getBasePADUrl({ communityId: parentCommunity.id }),
    tabs: padTabsPaths,
  });

  const carouselApi = useCarousel({
    pageOffset: [
      [24, 32],
      [16, 24],
    ],
    size: [2, 2, 3, 4, 5, 6],
  });

  const { infiniteQuery: communitiesChildrenSmallGroupsInfiniteQuery } =
    useRequestCommunitiesChildren({
      id: parentCommunity.id,
      type: CommunityType.small_group,
    });

  const shouldFetchNextPageCommhnitiesChildrenSmallGroupsInfiniteQuery =
    carouselApi.endIsVisible &&
    communitiesChildrenSmallGroupsInfiniteQuery.hasNextPage &&
    !communitiesChildrenSmallGroupsInfiniteQuery.isFetchingNextPage;

  useEffect(() => {
    if (shouldFetchNextPageCommhnitiesChildrenSmallGroupsInfiniteQuery)
      communitiesChildrenSmallGroupsInfiniteQuery.fetchNextPage();
  }, [shouldFetchNextPageCommhnitiesChildrenSmallGroupsInfiniteQuery]);

  const childCommunities = (
    communitiesChildrenSmallGroupsInfiniteQuery.data?.pages ?? []
  ).flatMap((page) => page.results);

  return (
    <Carousel
      carouselApi={carouselApi}
      containerStylexArray={[styles.carousel]}
    >
      {!communitiesChildrenSmallGroupsInfiniteQuery.data
        ? [...Array(LOADING_SKELETONS_TOTAL)].map((index) => (
            <AvatarCommunity key={index} loading size="responsive" />
          ))
        : [
            ...(childCommunities ?? []).map((community) => {
              return (
                <Link
                  href={`/admin/${parentCommunity.id}/dashboard/community/${community.id}`}
                  key={`communityCarouselItem-${community.id}`}
                  onClick={() =>
                    analytics.track({
                      event: HallowAnalyticsEvent.TappedGroup,
                      properties: {
                        parent_group_type: parentCommunity.type,
                        community_id: community.id,
                        location: activeSidebarTab
                          ? `${activeSidebarTab}_tab`
                          : "",
                        group_type: type,
                      },
                    })
                  }
                >
                  <AvatarCommunity
                    color={community.color_hex as CSSColorStrict}
                    name={community.name}
                    imgSrc={community.image_url}
                    size="responsive"
                    topLeftImgSrc={community.member_summary.image_urls?.[0]}
                    bottomRightImgSrc={community.member_summary.image_urls?.[1]}
                    sublabel={t("plural_members", {
                      plural_members: community.member_summary.member_count,
                    })}
                  />
                </Link>
              );
            }),
            ...(communitiesChildrenSmallGroupsInfiniteQuery.hasNextPage
              ? [<AvatarCommunity loading={true} size="responsive" />]
              : []),
          ]}
    </Carousel>
  );
};
