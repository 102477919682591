"use client";

import { I18nMessageClient } from "@packages/i18n";
import type { SidebarProps } from "@packages/ui";
import { Sidebar, Text } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import { useParams } from "next/navigation";

import { getBasePADUrl } from "../../../../lib";
import { MembersList } from "../MembersList";

const styles = stylex.create({
  base: {
    gap: spacing.xxs,
    paddingLeft: spacing.s,
    paddingRight: spacing.s,
  },
  header: {
    marginBottom: spacing.s,
    paddingBottom: spacing.s,
    paddingHorizontal: spacing.s,
  },
});

export type ParishAdminDashboardMembersSidebarProps = Omit<
  SidebarProps,
  "children"
>;

export const ParishAdminDashboardMembersSidebar = ({
  state,
  ...props
}: ParishAdminDashboardMembersSidebarProps) => {
  const { communityId } = useParams<{ communityId: string }>();

  return (
    <Sidebar state={state} styleXArray={[styles.base]} {...props}>
      <Text type="headline" size="m" styleXArray={[styles.header]}>
        <I18nMessageClient message="community_settings_members" />
      </Text>
      <MembersList
        communityId={parseInt(communityId)}
        baseMemberLinkUrl={`${getBasePADUrl({ communityId: parseInt(communityId) })}/members`}
      />
    </Sidebar>
  );
};
