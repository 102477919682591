"use client";

import { useTranslations } from "@packages/i18n";
import { type PaymentResult } from "@packages/sdk";
import { Button, Form, FormField, TextInput, useAlert } from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import stylex from "@stylexjs/stylex";
import type { HTMLAttributes } from "react";
import { useForm, type UseFormReturn } from "react-hook-form";

import { CheckoutWrapper } from "./CheckoutWrapper";
import { PaypalElement } from "./PaypalElement";
import { StripePaymentElement } from "./StripePaymentElement";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: spacing.s,
    width: spacing.full,
  },
  submit: {
    marginTop: spacing.m,
  },
});

export type CheckoutProps = {
  autoFocus?: boolean;
  buttonLabel?: string;
  currency: string;
  form?: UseFormReturn<Partial<PaymentResult>, any, undefined>;
  formProps?: HTMLAttributes<HTMLFormElement>;
  handleSubscription?: { priceId: string; promoCode: string };
  mode: "payment" | "subscription";
  onSubmit: (details: PaymentResult) => void;
  platforms?: ("stripe" | "paypal")[];
  requireEmail: boolean;
  requireName: boolean;
  themeOverride?: "light" | "dark";
  total: number;
};

const CheckoutInternal = ({
  platforms = ["paypal", "stripe"],
  formProps = {},
  ...props
}: CheckoutProps) => {
  let { autoFocus, form } = props;

  const elements = useElements();
  const stripe = useStripe();

  const alert = useAlert();
  const t = useTranslations();

  const onConfirm = async ({ email, name }: Partial<PaymentResult>) => {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      alert.show({
        title: t("general_word_error"),
        description: submitError.message,
      });
      return;
    }

    const { paymentMethod } = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: { email, name },
      },
    });
    const { address } = paymentMethod.billing_details;

    const paymentResult: PaymentResult = {
      address,
      email,
      name,
      paymentMethod: paymentMethod.id,
      paymentPlatform: "stripe",
    };

    await props.onSubmit(paymentResult);
  };

  const formNew = useForm<Partial<PaymentResult>>();
  form = form ?? formNew;

  return (
    <Form
      formProps={{
        onSubmit: form.handleSubmit(onConfirm),
        styleXArray: [styles.base],
        ...formProps,
      }}
      {...form}
    >
      {platforms.includes("stripe") && (
        <>
          {props.requireName && (
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <TextInput
                  autoFocus={autoFocus}
                  required
                  placeholder={t("user_payment_cardholder_placeholder")}
                  {...field}
                />
              )}
            />
          )}
          {props.requireEmail && (
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <TextInput
                  type="email"
                  required
                  placeholder={t("general_email")}
                  {...field}
                />
              )}
            />
          )}
          <StripePaymentElement form={form} onSubmit={onConfirm} />
        </>
      )}

      {platforms.includes("paypal") && <PaypalElement {...props} />}

      {props.buttonLabel && (
        <Button
          type="submit"
          disabled={
            !form.formState.isDirty ||
            !form.formState.isValid ||
            form.formState.isSubmitting
          }
          size="l"
          isFullWidth={true}
          styleXArray={[styles.submit]}
        >
          {props.buttonLabel}
        </Button>
      )}
    </Form>
  );
};

export const Checkout = (props: CheckoutProps) => {
  const { currency, mode, themeOverride, total } = props;

  return (
    <CheckoutWrapper
      currency={currency}
      mode={mode}
      themeOverride={themeOverride}
      total={total}
    >
      <CheckoutInternal {...props} />
    </CheckoutWrapper>
  );
};
