"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { type Community, URL_SELF, useActiveSidebarTab } from "@packages/sdk";
import type { CSSColorStrict, WithStylexArray } from "@packages/ui";
import {
  AvatarCommunity,
  FriendsIcon,
  QuickAction,
  ShareIcon,
  ShareModal,
  Tag,
  Text,
  useModal,
} from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useParams, usePathname } from "next/navigation";
import { useTranslations } from "next-intl";

import { getBasePADUrl, padTabsPaths } from "../../../../lib";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthTablet = "@media (min-width: 768px)";

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.l,
    justifyContent: "center",
    margin: "auto",
    maxWidth: "600px",
    width: spacing.full,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: spacing.l,
  },
  groupInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  groupInfoTextContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
  },
  groupInfoDescriptionDetailsContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    textAlign: "center",
  },
  shareModalImage: {
    height: "250px",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "250px",
  },
  shareModalContentContainer: {
    marginLeft: {
      [cssMediaMinWidthTablet]: "16%",
      default: spacing.m,
    },
    marginRight: {
      [cssMediaMinWidthTablet]: "16%",
      default: spacing.m,
    },
  },
});

type CommunityDetailsProps = WithStylexArray<{
  community: Pick<
    Community,
    | "id"
    | "name"
    | "description"
    | "image_url"
    | "color_hex"
    | "tag_text"
    | "simple_address_label"
  >;
}>;

export const CommunityDetails = ({
  community,
  styleXArray,
}: CommunityDetailsProps) => {
  const t = useTranslations();
  const shareModal = useModal(ShareModal);
  const analytics = useAnalytics();
  const pathname = usePathname();
  const { communityId } = useParams<{ communityId: string }>();

  const activeSidebarTab = useActiveSidebarTab({
    baseUrl: getBasePADUrl({
      communityId: parseInt(communityId),
    }),
    tabs: padTabsPaths,
  });

  return (
    <div {...stylex.props(styles.container, styleXArray)}>
      {community.image_url && (
        <AvatarCommunity
          size="xl"
          color={community.color_hex as CSSColorStrict}
          imgSrc={community.image_url}
        />
      )}
      <div {...stylex.props(styles.groupInfoTextContainer)}>
        <Text type="title" size="xl" as="h1">
          {community.name}
        </Text>
        {community.tag_text && (
          <Tag variant="neutral">{community.tag_text}</Tag>
        )}
      </div>
      <div {...stylex.props(styles.groupInfoDescriptionDetailsContainer)}>
        {community.description && (
          <Text type="body" size="m" as="p">
            {community.description}
          </Text>
        )}
      </div>
      <div {...stylex.props(styles.actionsContainer)}>
        <QuickAction key="quickActionMembers" icon={<FriendsIcon />} asChild>
          <Link
            href={`${pathname}${pathname.endsWith("/") ? "" : "/"}members`}
            onClick={() =>
              analytics.track({
                event: HallowAnalyticsEvent.TappedGroupMembers,
                properties: {
                  community_id: community.id,
                  location: activeSidebarTab ? `${activeSidebarTab}_tab` : "",
                },
              })
            }
          >
            {t("community_settings_members")}
          </Link>
        </QuickAction>
        <QuickAction
          key="quickActionShare"
          icon={<ShareIcon />}
          onClick={() => {
            shareModal.show({
              color: community.color_hex,
              imgSrc: community.image_url,
              title: community.name,
              description: community.simple_address_label,
              imgStyleXArray: [styles.shareModalImage],
              contentContainerStyleXArray: [styles.shareModalContentContainer],
              link: `${URL_SELF}/communities/${community.id}`,
            });
            analytics.track({
              event: HallowAnalyticsEvent.TappedGroupInvite,
              properties: {
                community_id: community.id,
                location: activeSidebarTab ? `${activeSidebarTab}_tab` : "",
              },
            });
          }}
        >
          {t("general_word_share")}
        </QuickAction>
      </div>
    </div>
  );
};
