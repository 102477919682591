"use client";

import { useTranslations } from "@packages/i18n";
import type { Menu } from "@packages/sdk";
import {
  URL_ACCESS,
  URL_SELF,
  usePathMatch,
  useRequestMe,
  useSession,
} from "@packages/sdk";
import {
  AccountIcon,
  Avatar,
  Button,
  CloseIcon,
  DownloadAppButton,
  DropdownMenuItem,
  FullscreenModal,
  FullscreenModalDropdownMenu,
  FullscreenModalFooter,
  FullscreenModalItem,
  HallowLogoHarold,
  HallowLogoText,
  IconButton,
  LogoutButton,
  MenuIcon,
  Notice,
  SearchIcon,
  stylexDynamics,
  useDynamicViewport,
} from "@packages/ui";
import {
  fontSizes,
  numericPixels,
  semanticColors,
  spacing,
  zIndices,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

import type { LINK } from "../../constants";
import { LINKS } from "../../constants";
import { MenuBarPage } from "../MenuBarPage";

/**
 * Constants
 * styleX requires these to be statically defined in this file
 */
const cssMediaMinWidthDesktop = "@media (min-width: 768px)";
const cssMediaMinWidthMobileSmall = "@media (min-width: 370px)";

const styles = stylex.create({
  dropdownMenuItem: {
    minWidth: numericPixels[240],
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end",
  },
  modalFullscreenFooter: {
    gap: spacing.m,
  },
  modalFullscreenFooterDivDynamic: {
    alignItems: "center",
    display: "flex",
    flexDirection: {
      [cssMediaMinWidthMobileSmall]: "row",
      default: "column",
    },
    gap: spacing.s,
  },
  modalFullscreenFooterDivVertical: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    marginTop: spacing.xl,
  },
  nav: {
    alignItems: "center",
    backgroundColor: semanticColors.background,
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    height: spacing.xxxl,
    paddingBottom: {
      [cssMediaMinWidthDesktop]: 0,
      default: spacing.m,
    },
    paddingTop: {
      [cssMediaMinWidthDesktop]: 0,
      default: spacing.m,
    },
    position: "sticky",
    top: spacing.none,
    zIndex: zIndices.menuBar,
  },
  authenticatedNav: {
    paddingLeft: {
      [cssMediaMinWidthDesktop]: spacing.m,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthDesktop]: spacing.m,
      default: spacing.l,
    },
  },
  unauthenticatedNav: {
    paddingLeft: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
    paddingRight: {
      [cssMediaMinWidthDesktop]: spacing.xxl,
      default: spacing.l,
    },
  },
  ul: {
    alignItems: "center",
    display: "flex",
    gap: spacing.s,
  },
  ulVertical: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.m,
  },
  widthFitContent: {
    width: "fit-content",
  },
  haroldLogo: {
    marginHorizontal: "10px",
    marginVertical: spacing.s,
  },
  studentBadge: {
    alignItems: "center",
    display: "flex",
    fontSize: fontSizes.bodyS,
    fontWeight: 600,
    gap: spacing.xs,
    margin: spacing.none,
    padding: spacing.xxs,
    paddingRight: spacing.s,
  },
});

export type MenuBarProps = { menu?: Menu };
export const MenuBar = ({ menu = [] }: MenuBarProps) => {
  const isDesktopViewport = useDynamicViewport({ minimumWidth: 1024 });
  const matcher = usePathMatch();
  const pathname = usePathname();
  const { status } = useSession();
  const { query: meQuery } = useRequestMe();
  const t = useTranslations();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) setIsMenuOpen(false);
  }, [pathname]);

  const trigger = (
    <IconButton
      icon={isMenuOpen ? <CloseIcon /> : <MenuIcon />}
      variant={"transparentPrimary"}
    />
  );

  return (
    <nav
      {...stylex.props(
        styles.nav,
        status === "authenticated"
          ? styles.authenticatedNav
          : styles.unauthenticatedNav,
      )}
    >
      <Link href="/" {...stylex.props(styles.widthFitContent)}>
        {status !== "authenticated" && <HallowLogoText />}
        {status === "authenticated" && (
          <HallowLogoHarold styleXArray={[styles.haroldLogo]} />
        )}
      </Link>

      <ul {...stylex.props(styles.ul)}>
        {status === "authenticated" && (
          <>
            {[LINKS.home, LINKS.search, LINKS.bible].map((link: LINK) => (
              <li
                key={link.message}
                {...stylex.props(stylexDynamics.displayTabletViewport())}
              >
                <MenuBarPage
                  icon={link.icon}
                  analyticsEvent={link.analyticsEvent}
                  {...("menuProps" in link ? link.menuProps : {})}
                  {...link.props}
                >
                  {t(link.message)}
                </MenuBarPage>
              </li>
            ))}
            <li {...stylex.props(stylexDynamics.displayTabletViewport())}>
              <MenuBarPage
                override={
                  <Avatar
                    selected={matcher(LINKS.me.props.href.toString())}
                    imgSrc={meQuery.data?.image_url}
                  />
                }
                analyticsEvent={LINKS.me.analyticsEvent}
                {...LINKS.me.props}
              >
                {t(LINKS.me.message)}
              </MenuBarPage>
            </li>
            {meQuery.data?.has_access_to_parish_admin_dashboard && (
              <li {...stylex.props(stylexDynamics.displayTabletViewport())}>
                <MenuBarPage
                  icon={LINKS.parishAdminDashboard.icon}
                  analyticsEvent={LINKS.parishAdminDashboard.analyticsEvent}
                  {...LINKS.parishAdminDashboard.props}
                >
                  {t(LINKS.parishAdminDashboard.message)}
                </MenuBarPage>
              </li>
            )}
          </>
        )}

        {status !== "authenticated" &&
          menu.map((link) => (
            <li
              key={link.id}
              {...stylex.props(stylexDynamics.displayDesktopViewport())}
            >
              <Button asChild variant="transparentPrimary">
                <Link href={link.url} target={link.target}>
                  {link.title}
                </Link>
              </Button>
            </li>
          ))}
      </ul>

      <ul {...stylex.props(styles.ul, styles.justifyContentFlexEnd)}>
        {status === "authenticated" && (
          <>
            {meQuery.data?.is_student && (
              <Notice
                icon={<AccountIcon />}
                styleXArray={[
                  stylexDynamics.displayDesktopViewport(),
                  styles.studentBadge,
                ]}
              >
                {t("web_menu_school_account")}
              </Notice>
            )}
            <FullscreenModalDropdownMenu
              dropdownMenuProps={{
                align: "end",
                children: (
                  <>
                    {[LINKS.giftCards, LINKS.account].map((link) => (
                      <DropdownMenuItem
                        asChild
                        key={link.message}
                        styleXArray={[styles.dropdownMenuItem]}
                      >
                        <Link {...link.props}>{t(link.message)}</Link>
                      </DropdownMenuItem>
                    ))}
                    <DropdownMenuItem
                      asChild
                      key={"login_title"}
                      styleXArray={[styles.dropdownMenuItem]}
                    >
                      <LogoutButton />
                    </DropdownMenuItem>
                  </>
                ),
              }}
              fullscreenModalProps={{
                children: (
                  <ul {...stylex.props(styles.ulVertical)}>
                    {[LINKS.search, LINKS.giftCards, LINKS.account].map(
                      (link) => (
                        <li key={link.message}>
                          <FullscreenModalItem asChild>
                            <Link {...link.props}>{t(link.message)}</Link>
                          </FullscreenModalItem>
                        </li>
                      ),
                    )}
                    <li key={"login_title"}>
                      <FullscreenModalItem asChild>
                        <LogoutButton type={"headline"} size={"l"} />
                      </FullscreenModalItem>
                    </li>
                  </ul>
                ),
              }}
              open={isMenuOpen}
              onOpenChange={() => setIsMenuOpen(!isMenuOpen)}
              trigger={<li>{trigger}</li>}
            />
          </>
        )}

        {status !== "authenticated" && (
          <>
            <li {...stylex.props(stylexDynamics.hideDesktopViewport())}>
              <IconButton
                asChild
                icon={<SearchIcon />}
                variant="transparentPrimary"
              >
                <Link {...LINKS.search.props} />
              </IconButton>
            </li>
            <li {...stylex.props(stylexDynamics.displayDesktopViewport())}>
              <Button
                asChild
                startIcon={<SearchIcon />}
                variant="transparentPrimary"
              >
                <Link {...LINKS.search.props}>{t(LINKS.search.message)}</Link>
              </Button>
            </li>
            <li {...stylex.props(stylexDynamics.displayDesktopViewport())}>
              <Button asChild variant="transparentPrimary">
                <Link
                  {...LINKS.login.props}
                  href={`${URL_ACCESS}?loginRedirect=${encodeURIComponent(`${URL_SELF}${pathname}`)}`}
                >
                  {t(LINKS.login.message)}
                </Link>
              </Button>
            </li>
            <li {...stylex.props(stylexDynamics.displayDesktopViewport())}>
              <Button asChild>
                <Link
                  {...LINKS.tryHallowForFree.props}
                  href={`${URL_ACCESS}/onboarding?page=subscribe&utm_source=website&utm_medium=website&utm_campaign=Website-NavLink&loginRedirect=${encodeURIComponent(`${URL_SELF}${pathname}`)}`}
                >
                  {t(LINKS.tryHallowForFree.message)}
                </Link>
              </Button>
            </li>
            {!isDesktopViewport && (
              <FullscreenModal
                open={isMenuOpen}
                onOpenChange={() => setIsMenuOpen(!isMenuOpen)}
                trigger={
                  <li {...stylex.props(stylexDynamics.hideDesktopViewport())}>
                    {trigger}
                  </li>
                }
              >
                <ul {...stylex.props(styles.ulVertical)}>
                  {menu.map((link) => (
                    <li key={link.id}>
                      <FullscreenModalItem asChild>
                        <Link href={link.url} target={link.target}>
                          {link.title}
                        </Link>
                      </FullscreenModalItem>
                    </li>
                  ))}
                </ul>
                <FullscreenModalFooter
                  styleXArray={[styles.modalFullscreenFooter]}
                >
                  <div
                    {...stylex.props(styles.modalFullscreenFooterDivVertical)}
                  >
                    <Button asChild isFullWidth size="l">
                      <Link {...LINKS.tryHallowForFree.props}>
                        {t(LINKS.tryHallowForFree.message)}
                      </Link>
                    </Button>
                    <Button
                      asChild
                      isFullWidth
                      variant="transparentPrimary"
                      size="l"
                    >
                      <Link
                        {...LINKS.login.props}
                        href={`${URL_ACCESS}?loginRedirect=${encodeURIComponent(`${URL_SELF}${pathname}`)}`}
                      >
                        {t(LINKS.login.message)}
                      </Link>
                    </Button>
                  </div>
                  <div
                    {...stylex.props(styles.modalFullscreenFooterDivDynamic)}
                  >
                    <DownloadAppButton isFullWidth store="apple" />
                    <DownloadAppButton isFullWidth store="google" />
                  </div>
                </FullscreenModalFooter>
              </FullscreenModal>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

MenuBar.displayName = "MenuBar";
