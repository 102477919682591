"use client";

import { Theme, useTheme } from "@packages/ui";
import { colorsRaw, spacingRaw } from "@packages/ui/global/stylex/vars.stylex";
import { Elements } from "@stripe/react-stripe-js";
import type { Appearance, StripeElementsOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { ReactNode } from "react";

import type { CheckoutProps } from "./Checkout";

export type CheckoutWrapperProps = Pick<
  CheckoutProps,
  "currency" | "mode" | "themeOverride" | "total"
> & {
  children: ReactNode;
};

const lightModeAppearance: Appearance["rules"] = {
  ".AccordionItem": {
    backgroundColor: colorsRaw.grey99,
    border: `2px solid ${colorsRaw.grey99}`,
    padding: "20px",
    color: colorsRaw.black,
  },
  ".AccordionItem--selected": {
    backgroundColor: colorsRaw.white,
    paddingBottom: "10px",
    color: colorsRaw.black,
    border: `2px solid ${colorsRaw.grey99}`,
  },
  ".Input": {
    backgroundColor: colorsRaw.white,
    border: `2px solid ${colorsRaw.grey99}`,
    padding: `${spacingRaw.ms} ${spacingRaw.m}`,
  },
  ".Label--resting": {
    fontSize: "15px",
    color: colorsRaw.black,
  },
  ".Label--floating": {
    fontSize: "13px",
    color: colorsRaw.grey40,
  },
};

const darkModeAppearance: Appearance["rules"] = {
  ".AccordionItem": {
    backgroundColor: colorsRaw.grey10,
    border: `2px solid ${colorsRaw.grey10}`,
    padding: "20px",
    color: colorsRaw.white,
  },
  ".AccordionItem--selected": {
    backgroundColor: colorsRaw.blackOLED,
    paddingBottom: "10px",
    color: colorsRaw.white,
    border: `2px solid ${colorsRaw.grey10}`,
  },
  ".Input": {
    backgroundColor: colorsRaw.blackOLED,
    border: `2px solid ${colorsRaw.grey10}`,
    color: colorsRaw.white,
    padding: `${spacingRaw.ms} ${spacingRaw.m}`,
  },
  ".Label--resting": {
    fontSize: "15px",
    color: colorsRaw.white,
  },
  ".Label--floating": {
    fontSize: "13px",
    color: colorsRaw.grey80,
  },
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

export const CheckoutWrapper = ({
  children,
  currency,
  mode,
  themeOverride,
  total,
}: CheckoutWrapperProps) => {
  const { currentTheme } = useTheme();
  const options: StripeElementsOptions = {
    appearance: {
      theme: "flat",
      labels: "floating",
      variables: {
        borderRadius: "10px",
      },
      rules:
        (themeOverride ?? currentTheme) === Theme.DARK
          ? darkModeAppearance
          : lightModeAppearance,
    },
    amount: Math.round(total * 100),
    currency: currency.toLowerCase(),
    mode,
    paymentMethodCreation: "manual",
  };

  return (
    <Elements options={options} stripe={stripePromise}>
      {children}
    </Elements>
  );
};
