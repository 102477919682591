"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import {
  CommunityType,
  useActiveSidebarTab,
  useRequestCommunitiesAdminCommunity,
  useRequestCommunitiesChildren,
} from "@packages/sdk";
import type { CSSColorStrict } from "@packages/ui";
import {
  AvatarCommunity,
  ListProfile,
  useIntersectionObserverRefState,
} from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import { useErrorNotification } from "@packages/ui/src/hooks/useErrorNotification";
import stylex from "@stylexjs/stylex";
import Link from "next/link";
import { useEffect, useRef } from "react";

import { getBasePADUrl, padTabsPaths } from "../../../../lib";
import { EditGroupDropdownMenu } from "../EditGroupDropdownMenu";
import { GroupsEmptyState } from "../GroupsEmptyState";

const styles = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.xxs,
  },
  listProfilePlaceHolder: {
    height: 40,
    width: 40,
  },
  groupContainer: {
    position: "relative",
  },
  groupIconButton: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const stylesLoading = stylex.create({
  flex: {
    display: "flex",
  },
  hidden: {
    display: "none",
  },
});

type GroupsListProps = {
  communityId: number;
};

const GROUPS_LIST_TOTAL_LOADING_SKELETONS = 6;

export const GroupsList = ({ communityId }: GroupsListProps) => {
  const t = useTranslations();
  const analytics = useAnalytics();

  const activeSidebarTab = useActiveSidebarTab({
    baseUrl: getBasePADUrl({ communityId }),
    tabs: padTabsPaths,
  });

  const infiniteScrollBufferRef = useRef<HTMLDivElement>(null);
  const [infiniteScrollBufferRefShowing] = useIntersectionObserverRefState({
    ref: infiniteScrollBufferRef,
  });

  const { infiniteQuery: communitiesChildrenSmallGroupsInfiniteQuery } =
    useRequestCommunitiesChildren({
      id: communityId,
      type: CommunityType.small_group,
    });

  const { query: parentCommunityQuery } = useRequestCommunitiesAdminCommunity({
    id: communityId,
  });

  useErrorNotification({
    isError: communitiesChildrenSmallGroupsInfiniteQuery.isError,
  });

  const shouldFetchNextPageCommunityGroupsInfiniteQuery =
    infiniteScrollBufferRefShowing &&
    communitiesChildrenSmallGroupsInfiniteQuery.hasNextPage &&
    !communitiesChildrenSmallGroupsInfiniteQuery.isFetchingNextPage;

  useEffect(() => {
    if (shouldFetchNextPageCommunityGroupsInfiniteQuery)
      communitiesChildrenSmallGroupsInfiniteQuery.fetchNextPage();
  }, [shouldFetchNextPageCommunityGroupsInfiniteQuery]);

  const childCommunities = (
    communitiesChildrenSmallGroupsInfiniteQuery.data?.pages ?? []
  ).flatMap((page) => page.results);

  if (communitiesChildrenSmallGroupsInfiniteQuery.isLoading)
    return (
      <div {...stylex.props(styles.base)}>
        {Array.from(Array(GROUPS_LIST_TOTAL_LOADING_SKELETONS).keys()).map(
          (i) => (
            <ListProfile key={`group-list-skeleton-${i}`} loading title="" />
          ),
        )}
      </div>
    );

  if (childCommunities.length === 0) return <GroupsEmptyState />;

  return (
    <>
      <div {...stylex.props(styles.base)}>
        {childCommunities.map((childCommunity) => (
          <div
            key={`group-list-item-${childCommunity.id}`}
            {...stylex.props(styles.groupContainer)}
          >
            <ListProfile
              asChild
              avatar={
                <AvatarCommunity
                  key={`avatar-${childCommunity.id}`}
                  imgSrc={childCommunity.image_url}
                  name={childCommunity.name}
                  color={childCommunity.color_hex as CSSColorStrict}
                  size="m"
                />
              }
              description={childCommunity.simple_address_label}
              icon={<div {...stylex.props(styles.listProfilePlaceHolder)} />}
              title={childCommunity.name}
              metadata={t("create_community_content_member_count", {
                create_community_content_member_count:
                  childCommunity.member_summary.member_count,
              })}
              onClick={() => {
                analytics.track({
                  event: HallowAnalyticsEvent.TappedGroup,
                  properties: {
                    parent_group_type: parentCommunityQuery.data.type,
                    community_id: childCommunity.id,
                    location: activeSidebarTab ? `${activeSidebarTab}_tab` : "",
                    group_type: childCommunity.type,
                  },
                });
              }}
            >
              <Link
                href={`${getBasePADUrl({ communityId })}/groups/${childCommunity.id}`}
              />
            </ListProfile>
            <EditGroupDropdownMenu
              communityId={communityId}
              childCommunity={childCommunity}
              triggerStyleXArray={[styles.groupIconButton]}
            />
          </div>
        ))}
      </div>
      <ListProfile
        loading
        ref={infiniteScrollBufferRef}
        styleXArray={[
          communitiesChildrenSmallGroupsInfiniteQuery.hasNextPage
            ? stylesLoading.flex
            : stylesLoading.hidden,
        ]}
        title=""
      />
    </>
  );
};
