"use client";

import { useTranslations } from "@packages/i18n";
import type { RadioStation } from "@packages/sdk";
import { useRequestMe } from "@packages/sdk";
import {
  Button,
  getRGBAFromColor,
  PlayIcon,
  stylexDynamics,
  SubscriptionIcon,
  Text,
  useIsDesktopViewport,
} from "@packages/ui";
import {
  colors,
  numericPercentages,
  numericPixels,
  radius,
  semanticColors,
  spacing,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import Image from "next/image";

// Importing pseudo-classes from another file is currently not supported by stylex
const IS_DESKTOP = "@media (min-width: 768px)";

const styles = stylex.create({
  container: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    outline: {
      [IS_DESKTOP]: `${numericPixels[4]} solid transparent`,
      default: `${numericPixels[2]} solid transparent`,
    },
    scrollSnapAlign: "center",
    transition:
      "outline 150ms ease-in, opacity 150ms ease-in, scale 150ms ease-in",
    width: numericPercentages[100],
  },
  imageContainer: {
    aspectRatio: "4 / 3",
    height: {
      [IS_DESKTOP]: "unset",
      default: "35vh",
    },
    marginTop: {
      [IS_DESKTOP]: spacing.xl,
      default: spacing.none,
    },
    position: "relative",
    width: numericPercentages[100],
  },
  image: {
    background: semanticColors.neutralsMedium,
    borderRadius: {
      [IS_DESKTOP]: radius.s,
      default: radius.none,
    },
    objectFit: "cover",
    objectPosition: "center",
    objectRepeat: "no-repeat",
  },
  gradientBackgroundImage: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    marginBottom: "-1px",
    paddingHorizontal: spacing.m,
    paddingTop: spacing.xxxl,
    position: "absolute",
    width: numericPercentages[100],
    zIndex: 1,
  },
  textTitle: {
    color: semanticColors.onColorHighest,
    overflow: "hidden",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: numericPercentages[100],
  },
  buttonContainer: {
    background: {
      [IS_DESKTOP]: colors.transparent,
      default: colors.black,
    },
    paddingBottom: spacing.l,
    paddingLeft: {
      [IS_DESKTOP]: spacing.none,
      default: spacing.l,
    },
    paddingRight: {
      [IS_DESKTOP]: spacing.none,
      default: spacing.l,
    },
    paddingTop: spacing.m,
  },
});

type RadioStationImageHeaderProps = {
  radioStation: RadioStation;
  playCTA: () => void;
};

export const RadioStationImageHeader = ({
  radioStation,
  playCTA,
}: RadioStationImageHeaderProps) => {
  const isDesktop = useIsDesktopViewport();

  const t = useTranslations();

  const { query: user } = useRequestMe();

  if (!radioStation) return null;

  const {
    images: { color_hex, large: imageLarge },
    has_access,
    name,
  } = radioStation;

  const rgbaColor = getRGBAFromColor({
    color: color_hex ?? "#000000",
    format: "object",
  });

  const image = (
    <Image
      src={imageLarge}
      alt={name}
      fill={true}
      {...stylex.props(styles.image)}
    />
  );

  const playButton = (
    <Button
      isFullWidth={true}
      onClick={() => playCTA()}
      size="l"
      startIcon={has_access ? <PlayIcon.Small /> : <SubscriptionIcon.Filled />}
      variant={isDesktop ? "primary" : "secondary"}
    >
      {t(has_access ? "played_radio_station" : "button_subscribe_to_listen")}
    </Button>
  );

  if (isDesktop)
    return (
      <>
        <Text size="m" type="headline">
          {name}
        </Text>
        <div {...stylex.props(styles.imageContainer)}>{image}</div>
        <div {...stylex.props(styles.buttonContainer)}>{playButton}</div>
      </>
    );

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.imageContainer)}>
        <div
          {...stylex.props(
            styles.gradientBackgroundImage,
            stylexDynamics.backgroundGradient({
              red: rgbaColor.r,
              green: rgbaColor.g,
              blue: rgbaColor.b,
              direction: "to bottom",
            }),
          )}
        >
          <Text size="s" type="headline" styleXArray={[styles.textTitle]}>
            {name}
          </Text>
        </div>
        {image}
      </div>
      <div
        {...stylex.props(
          styles.buttonContainer,
          stylexDynamics.backgroundColorFromRGBAObject(rgbaColor),
        )}
      >
        {playButton}
      </div>
    </div>
  );
};
