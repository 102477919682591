"use client";

import { useTranslations } from "@packages/i18n";
import type {
  HallowElement,
  HallowElementProps,
  WithStylexArray,
} from "@packages/ui";
import {
  Button,
  Carousel,
  Mask,
  Text,
  useCarousel,
  useIsDesktopViewport,
} from "@packages/ui";
import {
  dropShadow,
  numericPercentages,
  radius,
  semanticColors,
  shades,
  spacing,
  zIndices,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import { useState } from "react";

const styles = stylex.create({
  container: {
    paddingBottom: spacing.m,
  },
  carouselContainer: {
    alignItems: "center",
  },
  card: {
    alignItems: "flex-start",
    background: semanticColors.neutralsLowest,
    borderRadius: radius.m,
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    justifyContent: "center",
    padding: spacing.l,
    width: spacing.full,
  },
  cardDesktop: (width: number) => ({
    minHeight: "138px",
    width: `${width}px`,
  }),
  attribution: {
    color: semanticColors.neutralsMedHigh,
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.s,
    paddingBottom: spacing.m,
    paddingHorizontal: spacing.l,
    position: "relative",
    width: spacing.full,
  },
  button: {
    bottom: 28,
    boxShadow: `${dropShadow.none} ${dropShadow.s} ${dropShadow.ms} ${dropShadow.none} ${shades.shade10}`,
    left: numericPercentages[50],
    position: "absolute",
    transform: "translateX(-50%)",
    zIndex: zIndices.maskOver,
  },
  mask: {
    bottom: 0,
    left: 0,
    position: "absolute",
  },
});

const Element: HallowElement = "div";

export type TestimonialsProps = WithStylexArray<
  HallowElementProps<typeof Element>
>;

type Testimonial = {
  message: string;
  attribution?: string;
  width: number;
};

const testimonials: Testimonial[] = [
  {
    message: "web_app_homepage_quote_share_blessing",
    width: 595,
  },
  {
    message: "web_app_homepage_quote_thank_you",
    width: 380,
  },
  {
    message: "a_weary_soul_quote",
    width: 650,
  },
  {
    message: "web_home_user_quote_start_prayer_life",
    width: 400,
  },
  {
    message: "web_home_user_quote_quiet_prayer",
    width: 300,
  },
  {
    message: "web_app_homepage_quote_prayed_more",
    width: 300,
  },
];

const NO_OF_TRUNCATED_TESTIMONIALS = 3;

export const Testimonials = ({ styleXArray }: TestimonialsProps) => {
  const t = useTranslations();
  const carouselApi = useCarousel({
    size: "m",
    hoverButtons: false,
    fixedWidth: false,
  });
  const isDesktop = useIsDesktopViewport();
  const [truncateTestimonialsMobile, setTruncateTestiomonialsMobile] =
    useState(true);

  if (isDesktop) {
    return (
      <Carousel
        carouselApi={carouselApi}
        styleXArray={[styles.container, styleXArray]}
        containerStylexArray={[styles.carouselContainer]}
      >
        {testimonials.map((testimonial, index) => {
          return (
            <div
              key={`testimonialDesktop-${index}`}
              {...stylex.props(
                styles.card,
                styles.cardDesktop(testimonial.width ?? 300),
              )}
            >
              <Text
                as="p"
                type="detail"
                size="xl"
                styleXArray={[styles.attribution]}
              >
                {testimonial.attribution ??
                  t("quote_general_hallow_user_attribution")}
              </Text>
              <Text as="p" type="body" size="l">
                {t(testimonial.message)}
              </Text>
            </div>
          );
        })}
      </Carousel>
    );
  }

  return (
    <div {...stylex.props(styles.mobileContainer, styleXArray)}>
      {testimonials
        .slice(
          0,
          truncateTestimonialsMobile ? NO_OF_TRUNCATED_TESTIMONIALS : undefined,
        )
        .map((testimonial, index) => {
          return (
            <div
              key={`testimonialMobile-${index}`}
              {...stylex.props(styles.card)}
            >
              <Text type="detail" size="xl" styleXArray={[styles.attribution]}>
                {testimonial.attribution ??
                  t("quote_general_hallow_user_attribution")}
              </Text>
              <Text type="body" size="m">
                {t(testimonial.message)}
              </Text>
            </div>
          );
        })}
      {truncateTestimonialsMobile && (
        <>
          <Button
            styleXArray={[styles.button]}
            variant="white"
            size="s"
            onClick={() => setTruncateTestiomonialsMobile(false)}
          >
            {t("general_word_view_all").toUpperCase()}
          </Button>
          <Mask
            direction="to top"
            color={"background"}
            styleXArray={[styles.mask]}
            size="l"
          />
        </>
      )}
    </div>
  );
};

Testimonials.displayName = "Testimonials";
