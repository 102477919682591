"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import { useTranslations } from "@packages/i18n";
import { openEmail } from "@packages/sdk";
import type { WithStylexArray } from "@packages/ui";
import { Button } from "@packages/ui";

import { PAD_SUPPORT_EMAIL } from "../../../lib";

type ContactParishLeadButtonProps = WithStylexArray<{
  emailOptions?: {
    body?: string;
    subject?: string;
  };
}>;

export const ContactParishLeadButton = ({
  emailOptions = {},
  styleXArray,
}: ContactParishLeadButtonProps) => {
  const t = useTranslations();
  const analytics = useAnalytics();
  return (
    <Button
      size="l"
      variant="neutral"
      type="button"
      onClick={() => {
        analytics.track({
          event: HallowAnalyticsEvent.TappedContactHallowParishLead,
        });
        openEmail({ email: PAD_SUPPORT_EMAIL, ...emailOptions });
      }}
      styleXArray={styleXArray}
    >
      {t("contact_hallow_parish_lead")}
    </Button>
  );
};
