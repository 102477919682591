"use client";

import { Link, useTranslations } from "@packages/i18n";
import type { Article } from "@packages/sdk";
import {
  CategoryLarge,
  Container,
  SectionCarousel,
  Stack,
  Text,
} from "@packages/ui";
import { spacing } from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";

const styles = stylex.create({
  base: {
    paddingTop: spacing.m,
  },
  title: {
    alignItems: "center",
    display: "flex",
    gap: spacing.m,
    justifyContent: "space-between",
  },
});

export type LatestArticlesProps = {
  articles: Article[];
};

export const ArticleCard = ({ article }: { article: Article }) => {
  const category = article.categories.find(
    (category) => category.slug !== "uncategorized",
  );

  return (
    <CategoryLarge
      description={category?.name}
      title={article.title}
      titleProps={{
        overflow: null,
      }}
      color={article.images.color_hex as any}
      imgSrc={article.images}
      asChild
    >
      <Link href={article.link} target="_blank" />
    </CategoryLarge>
  );
};

ArticleCard.displayName = "ArticleCard";

export const LatestArticles = ({ articles }: LatestArticlesProps) => {
  const t = useTranslations();

  if (!articles?.length) return null;

  return (
    <Stack>
      <Container paddingX styleXArray={[styles.base]}>
        <div {...stylex.props(styles.title)}>
          <Text as="h2" overflow="ellipsis" size="m" type="headline">
            {t("web_latest_articles_title")}
          </Text>
        </div>
      </Container>
      <SectionCarousel size="m">
        {articles.map((article) => (
          <ArticleCard key={article.id} article={article} />
        ))}
      </SectionCarousel>
    </Stack>
  );
};

LatestArticles.displayName = "LatestArticles";
