import type { AnalyticsEvent } from "@packages/analytics";
import { HallowAnalyticsEvent } from "@packages/analytics";
import { URL_ACCESS, URL_SELF } from "@packages/sdk";
import type { TabBarIcon } from "@packages/ui";
import {
  BibleIcon,
  CommunityIcon,
  DashboardIcon,
  HomeIcon,
  SearchIcon,
} from "@packages/ui";
import type Link from "next/link";
import type { ComponentProps } from "react";

import type { MenuBarPage } from "../components";

export type LINK = {
  icon?: TabBarIcon;
  message?: string;
  props: ComponentProps<typeof Link>;
  menuProps?: Partial<ComponentProps<typeof MenuBarPage>>;
  analyticsEvent?: AnalyticsEvent;
};

export const LINKS: {
  [K: string]: {
    icon?: TabBarIcon;
    message?: string;
    props: ComponentProps<typeof Link>;
    analyticsEvent?: AnalyticsEvent;
  };
} = {
  account: {
    message: "settings_title",
    props: {
      href: "/settings",
    },
  },
  bible: {
    icon: BibleIcon,
    message: "bible_title",
    props: {
      href: "/bible",
    },
  } as LINK,
  categories: {
    message: "search_category_categories",
    props: {
      href: "/meditate", // TODO: add /categories route, and update this link
    },
  },
  community: {
    icon: CommunityIcon,
    message: "tab_title_community",
    props: {
      href: "/community",
    },
  },
  dailyReadings: {
    message: "daily_readings_title",
    props: {
      href: "/daily-readings",
    },
  },
  giftCards: {
    message: "web_menu_gift_cards",
    props: {
      href: "/gift",
    },
  },
  home: {
    icon: HomeIcon,
    message: "navigation_menu_title_home",
    props: {
      href: "/",
    },
  },
  login: {
    message: "login_title",
    props: {
      href: `${URL_ACCESS}?loginRedirect=${URL_SELF}`,
      target: "_top",
    },
  },
  me: {
    message: "navigation_menu_title_profile",
    props: {
      href: "/library",
    },
  },
  resources: {
    message: "web_menu_resources",
    props: {
      href: "/resources",
    },
  },
  schools: {
    message: "web_menu_schools",
    props: {
      href: "/schools",
    },
  },
  search: {
    icon: SearchIcon,
    message: "navigation_menu_title_search",
    props: {
      href: "/search",
    },
  },
  tryHallowForFree: {
    message: "try_hallow_for_free",
    props: {
      href: `${URL_ACCESS}/onboarding?page=subscribe&loginRedirect=${URL_SELF}`,
      target: "_top",
    },
  },
  parishAdminDashboard: {
    icon: DashboardIcon,
    message: "web_dashboard",
    props: {
      href: "/admin",
    },
    analyticsEvent: {
      event: HallowAnalyticsEvent.TappedWebAppNav,
      properties: {
        tab: "Admin Dashboard",
      },
    },
  },
} satisfies Record<string, LINK>;
